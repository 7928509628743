import React,{useEffect,useState} from 'react'
import _ from 'lodash'
import Layout from '../../layouts'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
export default (props)=>{
    const list = props && props.pageContext && props.pageContext.tags
    const [preletter,setPreletter] = useState([])

    useEffect(()=>{
        if(preletter.length === 0 && list){           
            const listdata = list
            let newpreletter = []
            let flistdata = []
            listdata.forEach(el =>{
                newpreletter.push(el.toLowerCase().charAt(0))
            })
            newpreletter = _.uniq(newpreletter)
            newpreletter.forEach(el=>{
                let stt = list.filter(ell=>ell.toLowerCase().charAt(0) === el)
                let obj={
                    letter:el,
                    data:stt
                }
                flistdata.push(obj)
            })

            setPreletter([...flistdata])
        }
    },[preletter])
    return(
        <Layout page={'tags-list formal'}>
            <Helmet title="Tags | JDSolutions"></Helmet>
        <div className="section blog-top-padding">
        <div className="white-bg blog-main-sections">
         <div className="title"><h2>Tags</h2></div>
         <div className="list-wrapper">
         {preletter.length !==0 && preletter.map((el,i)=>{
                return(<React.Fragment>
                    <h3 className="alpha">{el.letter}</h3>
                     <ul>
                    {el.data.map((elll,iii)=>{
                        return(
                        <li>
                        <Link to={`/designs/tags/${_.kebabCase(elll)}/`}>{elll}</Link>
                        </li>)
                    })}
                </ul>
                </React.Fragment>)
             })}
         </div>
        </div>
        </div>
            
        </Layout>
    )
}